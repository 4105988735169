<template>
  <v-container fluid>
    <v-row>
      <v-form ref="form" @submit.prevent="validateBeforeSubmit">
        <h4>Who is the provider's primary contact for this incident or allegation?</h4>

        <v-col cols="12">
          <v-text-field
            v-model="vm.contactTitle"
            label="Title"
            v-validate="'required'"
            data-vv-as="Title"
            name="contactTitle"
            :error-messages="errors.collect('contactTitle')"
          ></v-text-field>
        </v-col>
        <v-col cols="12">
          <v-text-field
            v-model="vm.contactFirstName"
            label="First Name"
            v-validate="'required'"
            data-vv-as="First Name"
            name="contactFirstName"
            :error-messages="errors.collect('contactFirstName')"
          ></v-text-field>
        </v-col>

        <v-col cols="12">
          <v-text-field
            v-model="vm.contactLastName"
            label="Last Name"
            v-validate="'required'"
            data-vv-as="Last Name"
            name="contactLastName"
            :error-messages="errors.collect('contactLastName')"
          ></v-text-field>
        </v-col>

        <v-col cols="12">
          <v-text-field
            v-model="vm.contactPositionAtProvider"
            label="Position at provider"
            v-validate="'required'"
            data-vv-as="Position at provider"
            name="contactPositionAtProvider"
            :error-messages="errors.collect('contactPositionAtProvider')"
          ></v-text-field>
        </v-col>

        <v-col cols="12">
          <v-text-field
            v-model="vm.contactPhoneNumber"
            label="Phone number"
            v-validate="'required'"
            data-vv-as="Phone number"
            name="contactPhoneNumber"
            :error-messages="errors.collect('contactPhoneNumber')"
          ></v-text-field>
        </v-col>

        <v-col cols="12">
          <v-text-field
            v-model="vm.contactEmail"
            label="Email address"
            v-validate="'required'"
            data-vv-as="Email address"
            name="contactEmail"
            :error-messages="errors.collect('contactEmail')"
          ></v-text-field>
        </v-col>

        <v-col cols="12">
          <v-text-field
            v-model="vm.contactPreferredMethodOfContact"
            label="Preferred method of contact"
            v-validate="'required'"
            data-vv-as="Preferred method of contact"
            name="contactPreferredMethodOfContact"
            :error-messages="errors.collect('contactPreferredMethodOfContact')"
          ></v-text-field>
        </v-col>

        <v-btn color="primary" type="submit">Continue</v-btn>
      </v-form>
    </v-row>
  </v-container>
</template>


<script>
import Vue from "vue";
import VeeValidate from "vee-validate";

Vue.use(VeeValidate);

export default {
  name: "primary-contact",

  data: () => ({
    vm: [],
    title: ["Mr", "Mrs", "Ms", "Miss", "Dr"]
  }),
  props: {
    msg: String
  },
  mounted() {
    this.init();
  },
  computed: {
    user() {
      return this.$store.getters.user;
    },
    currentOrganisation() {
      return this.$store.getters.currentOrganisation;
    },
    currentCase() {
      return this.$store.getters.currentCase;
    },
    currentIncident() {
      return this.$store.getters.currentIncident;
    }
  },
  methods: {
    init() {
      this.vm =
        this.currentIncident == undefined ||
        this.currentIncident.primaryContact == undefined
          ? {}
          : this.currentIncident.primaryContact;
    },

    validateBeforeSubmit() {
      this.$validator.validateAll().then(result => {
        if (result) {
          this.currentIncident.primaryContact = this.vm;
          this.$store.dispatch("setCurrentIncident", this.currentIncident);
          this.next();
          return;
        } else {
          this.$store.dispatch("setToast", {
            message: "Form errors please correct before continuing",
            color: "red darken-4"
          });
        }
      });
    },

    next() {
      this.$emit("next-step");
    },
    reset() {
      this.$refs.form.reset();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>

